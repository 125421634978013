import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../Context/UserContext';

type LoginBody = {
  email: string;
  password: string;
};
interface LoginObject {
  login: (body: LoginBody) => Promise<void>;
}

const baseUrl =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

/* eslint-disable-next-line */
export const getServices = (url: string, sessionId: string): Promise<any> => {
  return fetch(baseUrl + url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: sessionId },
  });
};

export const postService = (
  url: string,
  body: object,
  sessionId?: string
): Promise<Response> =>
  fetch(baseUrl + url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionId || '',
    },
  });

export const putService = (
  url: string,
  body: object,
  sessionId?: string
): Promise<Response> =>
  fetch(baseUrl + url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionId || '',
    },
  });

export const deleteService = (
  url: string,
  sessionId?: string
): Promise<Response> =>
  fetch(baseUrl + url, {
    method: 'DELETE',
    headers: { Authorization: sessionId || '' },
  });

export const useLoginService = (): LoginObject => {
  const [, setCookie] = useCookies();
  const user = useContext(UserContext);
  const login = async (body: LoginBody): Promise<void> =>
    fetch(`${baseUrl}/login`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((value) => {
        setCookie('sessionId', value.token, { maxAge: 36000 });
        setCookie('loggedUserId', value.user.id, { maxAge: 36000 });
        user.setUser(value.user);
      });
  return {
    login,
  };
};
