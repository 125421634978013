import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface Props {
  active: boolean;
  isOnHold?: boolean;
}

const statusIndicator: React.FC<Props> = (props: Props) => {
  const { active, isOnHold } = props;
  const getColor = (): string => {
    if (active) return 'red';
    return isOnHold ? 'orange' : 'green';
  };

  return (
    <div>
      <FiberManualRecordIcon htmlColor={getColor()} />
    </div>
  );
};

export default statusIndicator;
