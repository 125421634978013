import React from 'react';
import { Fab as MuiFab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

interface Props {
  icon?: React.JSXElementConstructor<object>;
  action: () => void;
}

const Fab: React.FC<Props> = (props: Props) => {
  const { icon: Icon = AddIcon, action } = props;
  return (
    <>
      <MuiFab
        style={{ position: 'absolute', bottom: 25, right: 25 }}
        onClick={action}
        color="primary"
        aria-label="add"
      >
        <Icon />
      </MuiFab>
    </>
  );
};

export default Fab;
