import { TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import Dialog from '../../Component/Dialog/index';
import Layout from '../../Component/layout';
import VirtualizedTable from '../../Component/VirtTable';
import { useFetch, useServerModification } from '../../Hooks/Hooks';

interface ClientCreationObject {
  'name-around': string;
  codeClient: string;
}
export interface Client {
  'name-around': string;
  codeClient: string;
}
const ClientPage: React.FC = () => {
  const clients = useFetch<Array<Client>>('/clients');
  const serverActions = useServerModification();
  const [openForm, setOpenForm] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const initialValues = {
    'name-around': '',
    codeClient: '',
  };
  const createClient = (values: ClientCreationObject): Promise<void> =>
    serverActions
      .sendPost('/clients', values)
      .then(() => clients.fetch())
      .then(() => setOpenForm(false));

  if (!clients.values) {
    return <></>;
  }
  const rows = [...clients.values].filter(
    (e) =>
      e.codeClient.toLowerCase().includes(filterText) ||
      (e['name-around'] && e['name-around'].toLowerCase().includes(filterText))
  );
  const columnWidth = window.innerWidth / 2;
  return (
    <>
      <Layout title="Clients" action={(): void => setOpenForm(true)}>
        <div style={{ height: 'calc(100% - 56px' }}>
          <TextField
            variant="outlined"
            placeholder="Rechercher"
            fullWidth
            onChange={(e): void => setFilterText(e.target.value.toLowerCase())}
          />
          <VirtualizedTable
            headerHeight={40}
            rowHeight={40}
            rowCount={rows.length || 0}
            rowGetter={({ index }): Client => rows[index]}
            columns={[
              {
                width: columnWidth,
                label: 'Code',
                dataKey: 'codeClient',
              },
              {
                width: columnWidth,
                label: 'Nom',
                dataKey: 'name-around',
              },
            ]}
          />
        </div>
      </Layout>

      <Formik initialValues={initialValues} onSubmit={createClient}>
        {(form): JSX.Element => (
          <Dialog
            open={openForm}
            title="Ajouter un Client"
            onClose={(): void => setOpenForm(false)}
            onValidate={(): Promise<void> => createClient(form.values)}
            isValid={form.isValid}
          >
            <div>
              <TextField
                label="Name"
                name="name-around"
                onChange={(e): void => form.handleChange(e)}
                style={{ marginRight: 15 }}
              />
              <TextField
                label="Code"
                name="codeClient"
                onChange={(e): void => form.handleChange(e)}
                style={{ marginRight: 15 }}
              />
            </div>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default ClientPage;
