import React from 'react';
import { Typography } from '@material-ui/core';

const NotFound: React.FC = () => (
  <div
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Typography variant="h1">404</Typography>
    <Typography variant="h3">Page Not Found</Typography>
  </div>
);

export default NotFound;
