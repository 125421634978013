import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Layout from '../../Component/layout';
import Dialog from '../../Component/Dialog';
import Status from '../../Component/statusIndicator';
import Select from '../../Component/Select';
import { useFetch, useServerModification } from '../../Hooks/Hooks';

type UserTypes = 'admin' | 'assistant' | 'viewer' | 'user';

interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: UserTypes;
  active: number;
}

const initialvalues = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  password: 'petrossian2020',
  type: 'user',
  active: 1,
} as User;

const UsersPage: React.FC = () => {
  const users = useFetch<Array<User> | undefined>('/users');
  const [openForm, setOpenForm] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<User>();
  const serverActions = useServerModification();
  const getHighestId = (): number => {
    let id = 0;
    if (users.values) {
      users.values.forEach((e) => {
        if (e.id > id) id = e.id;
      });
    }
    return id;
  };

  const createUser = (values: User, form: FormikHelpers<User>): void => {
    const id = getHighestId() + 1;
    serverActions
      .sendPost('/users', { ...values, id })
      .then(() => users.fetch());
    form.setSubmitting(false);
    setOpenForm(false);
  };

  const editUser = (form: FormikProps<User>): void => {
    serverActions.sendPut(`/users/${form.values.id}`, form.values).then(() => {
      users.fetch();
      setSelectedUser(undefined);
    });
    form.setSubmitting(false);
  };

  const toggleActive = (form: FormikProps<User>): void => {
    if (form.values.active === 0) {
      form.setFieldValue('active', 1);
    } else {
      form.setFieldValue('active', 0);
    }
  };

  const resetPassword = (): void => {
    if (selectedUser)
      serverActions.sendPut(`/users/${selectedUser.id}/reset`, {});
  };

  const handleOpenEdit = (id: number): void => {
    if (users.values) setSelectedUser(users.values.find((e) => e.id === id));
  };

  if (!users.values) {
    return <></>;
  }

  return (
    <>
      <Layout title="Utilisateur" action={(): void => setOpenForm(true)}>
        <TableContainer style={{ height: '100%' }}>
          <Table stickyHeader>
            <TableHead style={{ position: 'sticky' }}>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Prenom</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.values &&
                users.values.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={(): void => handleOpenEdit(row.id)}
                  >
                    <TableCell>{`${row.lastName} `}</TableCell>
                    <TableCell>{`${row.firstName}`}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <Status active={row.active !== 1} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Layout>
      <Formik
        initialValues={initialvalues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Format d'email incorrect")
            .required('Champ Requis')
            .notOneOf(
              users.values.map((e) => e.email),
              'Cet email existe déjà'
            ),
        })}
        onSubmit={createUser}
      >
        {(form: FormikProps<User>): JSX.Element => (
          <Dialog
            open={openForm}
            title="Ajouter Utilisateur"
            onClose={(): void => setOpenForm(false)}
            onValidate={form.handleSubmit}
            isValid={form.isValid}
          >
            <div>
              <TextField
                label="Prénom"
                name="firstName"
                onChange={(e): void => form.handleChange(e)}
                style={{ marginRight: 15 }}
              />
              <TextField
                label="Nom"
                name="lastName"
                onChange={(e): void => form.handleChange(e)}
                style={{ marginRight: 15 }}
              />
            </div>
            <TextField
              label="E-mail"
              name="email"
              error={!!form.errors.email}
              helperText={form.errors.email}
              onChange={(e): void => form.handleChange(e)}
              style={{ marginRight: 15 }}
            />
            <Select
              fieldName="type"
              label="Role"
              options={[
                { title: 'Consultation', value: 'viewer' },
                { title: 'Utilisateur', value: 'user' },
                { title: 'Assistant', value: 'assistant' },
                { title: 'Administrateur', value: 'admin' },
              ]}
            />
          </Dialog>
        )}
      </Formik>
      {selectedUser && (
        <Formik
          initialValues={selectedUser}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Format d'email incorrect")
              .required('Champ Requis')
              .notOneOf(
                users.values
                  .filter((e) => e.id !== selectedUser.id)
                  .map((e) => e.email),
                'Cet email existe déjà'
              ),
          })}
          onSubmit={createUser}
        >
          {(form): JSX.Element => (
            <Dialog
              open={!!selectedUser}
              title="Editer Utilisateur"
              onClose={(): void => setSelectedUser(undefined)}
              onValidate={(): void => editUser(form)}
              isValid={form.isValid}
            >
              <div>
                <TextField
                  label="Prénom"
                  value={form.values.firstName}
                  name="firstName"
                  onChange={(e): void => form.handleChange(e)}
                  style={{ marginRight: 15 }}
                />
                <TextField
                  label="Nom"
                  value={form.values.lastName}
                  name="lastName"
                  onChange={(e): void => form.handleChange(e)}
                  style={{ marginRight: 15 }}
                />
              </div>
              <TextField
                label="E-mail"
                value={form.values.email}
                name="email"
                type="email"
                error={!!form.errors.email}
                helperText={form.errors.email}
                onChange={(e): void => form.handleChange(e)}
                style={{ marginRight: 15 }}
              />
              <Select
                fieldName="type"
                label="Role"
                options={[
                  { title: 'Utilisateur', value: 'user' },
                  { title: 'Assistant', value: 'assistant' },
                  { title: 'Administrateur', value: 'admin' },
                ]}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  onClick={resetPassword}
                  style={{ marginTop: 15 }}
                  color="secondary"
                >
                  Réinitialisation mot de passe
                  {serverActions.isLoading && <CircularProgress />}
                </Button>
                <Button
                  onClick={(): void => toggleActive(form)}
                  style={{ marginTop: 15 }}
                >
                  {form.values.active === 0 ? 'Activer' : 'Désactiver'}
                </Button>
              </div>
            </Dialog>
          )}
        </Formik>
      )}
    </>
  );
};

export default UsersPage;
