import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Menu,
  PeopleAlt,
  ShoppingBasket,
  BusinessCenter,
  VpnKey,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import './layout.css';
import { useSessionId } from '../Hooks/Hooks';
import { UserContext } from '../Context/UserContext';
import Fab from './Fab';

interface Props {
  children?: React.ReactNode;
  title: string;
  action?: () => void;
}

const Layout: React.FC<Props> = (props: Props) => {
  const { children = <></>, title, action = () => {} } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { removeCookie } = useSessionId();

  const toggleDrawer = (): void => {
    setOpenDrawer(!openDrawer);
  };

  const navigate = (to: string): void => {
    history.push(`/${to}`);
  };

  const logout = (): void => {
    removeCookie('sessionId');
    removeCookie('loggedUserId');
    history.push('/login');
  };

  return (
    <div style={{ height: 'calc(100% - 48px)', width: '100%' }}>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className="menuButton"
            onClick={toggleDrawer}
            color="inherit"
            aria-label="menu"
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
        <List>
          {user && user?.type === 'admin' && (
            <>
              <ListItem button onClick={(): void => navigate('orders')}>
                <ListItemIcon>
                  <ShoppingBasket />
                </ListItemIcon>
                <ListItemText>Commandes</ListItemText>
              </ListItem>
              <ListItem button onClick={(): void => navigate('clients')}>
                <ListItemIcon>
                  <BusinessCenter />
                </ListItemIcon>
                <ListItemText>Clients</ListItemText>
              </ListItem>
              <ListItem button onClick={(): void => navigate('users')}>
                <ListItemIcon>
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText>Utilisateur</ListItemText>
              </ListItem>
            </>
          )}
          <ListItem button onClick={(): void => logout()}>
            <ListItemIcon>
              <VpnKey />
            </ListItemIcon>
            <ListItemText>Déconnexion</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      {children}
      {user && user?.type !== 'user' && action && <Fab action={action} />}
    </div>
  );
};

export default Layout;
