import { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import * as serverServices from '../Services';

interface Cookie {
  removeCookie: (name: string, options?: CookieSetOptions) => void;
}

interface SessionIdObject extends Cookie {
  sessionId: string;
}

interface UserIdObject extends Cookie {
  userId: string;
}

/* eslint-disable-next-line */
type ServerErrorHandler = (request: Promise<Response>) => Promise<any>;

interface FetchHook<T> {
  values?: T;
  isLoading: boolean;
  fetch: (queryParam?: string) => Promise<unknown>;
}

interface ServerModificationHook {
  sendPost: (url: string, body: object) => Promise<void>;
  sendPut: (url: string, body: object) => Promise<void>;
  sendDelete: (url: string) => Promise<void>;
  isLoading: boolean;
}

export const useSessionId = (): SessionIdObject => {
  const [cookie, , removeCookie] = useCookies(['sessionId']);
  return { sessionId: cookie.sessionId, removeCookie };
};

export const useUserId = (): UserIdObject => {
  const [cookie, , removeCookie] = useCookies(['loggedUserId']);
  return { userId: cookie.loggedUserId, removeCookie };
};

const useHandleError = (): ServerErrorHandler => {
  const [, , removeCookie] = useCookies();
  const handleError = useCallback(
    (request: Promise<Response>) =>
      request.then((res) =>
        res.status === 403 && !res.url.includes('user')
          ? removeCookie('sessionId')
          : res.json()
      ),
    [removeCookie]
  );
  return handleError;
};

/* eslint-disable-next-line */
export const useFetch = <T = any>(url: string): FetchHook<T> => {
  const { sessionId } = useSessionId();
  const handleServerError = useHandleError();
  const [values, setValues] = useState<T>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchServer = useCallback(
    (queryParam?: string) => {
      setIsLoading(true);
      return handleServerError(
        serverServices.getServices(
          url + (queryParam ? `?${queryParam}` : ''),
          sessionId
        )
      )
        .then((value: T) => setValues(value))
        .finally(() => setIsLoading(false));
    },
    [url, sessionId, handleServerError]
  );

  useEffect(() => {
    sessionId && fetchServer();
  }, [fetchServer, sessionId]);

  return {
    values,
    isLoading,
    fetch: fetchServer,
  };
};

export const useServerModification = (): ServerModificationHook => {
  const [isLoading, setIsLoading] = useState(false);
  const handleServerError = useHandleError();
  const { sessionId } = useSessionId();

  const sendPost = useCallback(
    async (url: string, body: object) => {
      setIsLoading(true);
      return handleServerError(
        serverServices.postService(url, body, sessionId)
      ).finally(() => setIsLoading(false));
    },
    [sessionId, handleServerError]
  );

  const sendPut = useCallback(
    async (url: string, body: object) => {
      setIsLoading(true);
      await serverServices
        .putService(url, body, sessionId)
        .finally(() => setIsLoading(false));
    },
    [sessionId]
  );

  const sendDelete = useCallback(
    async (url: string) => {
      setIsLoading(true);
      await serverServices
        .deleteService(url, sessionId)
        .finally(() => setIsLoading(false));
    },
    [sessionId]
  );

  return {
    sendPost,
    sendPut,
    sendDelete,
    isLoading,
  };
};

export function useLocalStorage(key: string, initialValue?: object): object {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: React.ReactText | object): void => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return { storedValue, setValue };
}
