import { Button, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../icon.jpg';
import { useLoginService } from '../../Services';
import './LoginPage.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '15em',
      height: '100%',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(2),
      },
    },
  })
);

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const [body, setBody] = React.useState({ email: '', password: '' });
  const [error, setError] = React.useState('');
  const loginService = useLoginService();
  const history = useHistory();

  const handleClick = async (): Promise<void> => {
    await loginService
      .login(body)
      .catch((err) => {
        setError('E-mail et/ou mot de passe invalide');
        return Promise.reject(err);
      })
      .then(() =>
        body.password === 'petrossian2020'
          ? history.push('/login/password')
          : history.push('/orders')
      );
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBody({ ...body, email: e.target.value });
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setBody({ ...body, password: e.target.value });
  };

  return (
    <>
      <div className={`loginForm ${classes.root}`}>
        <img src={logo} alt="logo" />
        <Typography color="secondary">{error}</Typography>
        <TextField
          value={body.email}
          onChange={handleEmailChange}
          variant="outlined"
          type="email"
          label="E-mail"
        />
        <TextField
          value={body.password}
          onChange={handlePasswordChange}
          variant="outlined"
          type="password"
          label="Mot de Passe"
        />
        <Button onClick={handleClick} variant="contained" color="primary">
          Se connecter
        </Button>
      </div>
    </>
  );
};

export default LoginPage;
