import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

interface Props {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  onValidate?: () => void;
  isValid?: boolean;
}

const index: React.FC<Props> = (props: Props) => {
  const { open, title, children, onClose, onValidate, isValid } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onValidate && (
          <Button color="primary" onClick={onValidate} disabled={!isValid}>
            Valider
          </Button>
        )}
        <Button onClick={onClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};

export default index;
