import React, { ReactText, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  debounce,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import Layout from '../../Component/layout';
import Dialog from '../../Component/Dialog';
import Select from '../../Component/Select';
import StatusIndicator from '../../Component/statusIndicator';
import { useFetch, useServerModification, useUserId } from '../../Hooks/Hooks';
import { UserContext } from '../../Context/UserContext';
import { Client } from '../Clients';

interface DateOption {
  title: string;
  value: React.ReactText;
}

interface Order {
  id: number;
  orderCode: string;
  userId: string;
  clientCode: string;
  createdAt: string;
  assigned: React.ReactText;
  userFName: string;
  userLName: string;
  delay: number;
}

interface OrderCreation {
  prefix: string;
  location: string;
  date: ReactText;
  code: string;
  clientCode: string;
  delay: string;
}

const OrderPage: React.FC = () => {
  const orders = useFetch<Array<Order>>('/orders');
  const clients = useFetch<Array<Client>>('/clients');
  const { userId } = useUserId();
  const { user } = React.useContext(UserContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openAdminDialog, setOpenAdminDialog] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<Order>();
  const [filterText, setFilterText] = React.useState('');
  const [error, setError] = React.useState(false);
  const [next, setNext] = React.useState(false);
  const [intervals, setIntervals] = React.useState<Array<number>>([]);
  const serverActions = useServerModification();

  const assignOrder = React.useCallback(() => {
    if (selectedOrder) {
      serverActions.sendPut(`/orders/${selectedOrder.id}`, { userId });
      orders.fetch();
      setOpenDialog(false);
      setOpenAdminDialog(false);
    }
  }, [serverActions, selectedOrder, orders, userId]);

  const unassignOrder = React.useCallback(() => {
    if (selectedOrder)
      serverActions.sendPut(`/orders/${selectedOrder.id}`, { userId: 0 });
    orders.fetch();
    setOpenAdminDialog(false);
  }, [serverActions, selectedOrder, orders]);

  const activateOrder = React.useCallback(() => {
    if (selectedOrder)
      serverActions.sendPut(`/orders/${selectedOrder.id}/activate`, {});
    orders.fetch();
    setOpenAdminDialog(false);
  }, [serverActions, selectedOrder, orders]);

  const clearAllIntervals = useCallback(() => {
    intervals.forEach((id) => clearInterval(id));
  }, [intervals]);

  React.useEffect(() => {
    if (filterText === '' || !filterText) {
      setIntervals((prevState: number[]) => {
        prevState.push(setInterval(orders.fetch, 60000));
        return prevState;
      });
    } else clearAllIntervals();
    return (): void => {
      clearInterval();
    };
  }, [orders.fetch, filterText, clearAllIntervals]);

  const handleNext = (): void => setNext(true);

  const handleSubmit = (form: FormikProps<OrderCreation>): void => {
    submitOrder(form.values)
      .then(() => {
        form.resetForm();
        setNext(false);
      })
      .catch(() => Promise.reject());
    form.setSubmitting(false);
  };

  const submitOrder = (values: OrderCreation): Promise<void> => {
    setError(false);
    const body = {
      id: 0,
      /* eslint-disable-next-line */
      order_code: getOrderCode(values),
      /* eslint-disable-next-line */
      user_id: 0,
      /* eslint-disable-next-line */
      client_code: defaultOptions.includes(values.prefix) ? values.clientCode : values.prefix,
      /* eslint-disable-next-line */
      created_at: new Date().toLocaleString('fr-FR'),
      assigned: false,
      delay: values.delay,
    };
    return serverActions
      .sendPost('/orders', body)
      .then(() => orders.fetch())
      .then(() => setOpenForm(false))
      .catch((err) => {
        setError(true);
        return Promise.reject(err);
      });
  };

  const deleteOrder = (): void => {
    if (selectedOrder) {
      serverActions
        .sendDelete(`/orders/${selectedOrder.id}`)
        .then(() => orders.fetch())
        .then(() => setOpenAdminDialog(false))
        .then(() => setSelectedOrder(undefined));
    }
  };

  const debounceOrderFetch = useCallback(
    debounce(
      (searchValue: string) =>
        orders.fetch(`filter=${searchValue}&limit=${25}`),
      300
    ),
    [orders.fetch]
  );

  if (!orders.values || !clients.values) {
    return <></>;
  }

  const handleOpenDialog = (allow: boolean): void => {
    if (user.type === 'admin' || user.type === 'assistant')
      setOpenAdminDialog(true);
    if (allow && user.type !== 'viewer' && user.type !== 'admin')
      setOpenDialog(true);
  };

  const handleOpenForm = async (): Promise<void> => {
    await clients.fetch('limit=200');
    setOpenForm(true);
  };

  const handleCloseForm = (form: FormikProps<OrderCreation>): void => {
    setError(false);
    form.resetForm();
    setNext(false);
    setOpenForm(false);
  };

  const handleChange = (
    e: React.ChangeEvent<{}>,
    value: string,
    form: FormikProps<OrderCreation>
  ): void => {
    form.setFieldValue('clientCode', value);
  };

  const handleUserInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    clients.fetch(`name=${e.target.value}&limit=200`);
  };

  const locationOptions = [
    { title: 'AP01', value: 'AP01' },
    { title: 'AP02', value: 'AP02' },
    { title: 'CP01', value: 'CP01' },
  ];
  const prefixOptions = [
    { title: 'BPC', value: 'BPC' },
    { title: 'CVC', value: 'CVC' },
    { title: 'INTERNET', value: 'INTERNET' },
    { title: 'DRUGSTORE', value: 'DRUGSTORE' },
    { title: 'ARTUS', value: 'ARTUS' },
  ];

  const defaultOptions = prefixOptions.filter(opt => opt.value.length<=3).map(opt=>opt.value);

  const dateOptions = ((): DateOption[] => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const prevMonth = currentMonth - 1 <= 0 ? 12 : currentMonth - 1;
    const year1 = currentDate.getFullYear().toString().substr(2);
    const year2 =
      currentMonth - 1 <= 0
        ? (currentDate.getFullYear() - 1).toString().substr(2)
        : year1;
    return [
      {
        title: year1 + (currentMonth > 9 ? '' : '0') + currentMonth.toString(),
        value: year1 + (currentMonth > 9 ? '' : '0') + currentMonth.toString(),
      },
      {
        title: year2 + (prevMonth > 9 ? '' : '0') + prevMonth.toString(),
        value: year2 + (prevMonth > 9 ? '' : '0') + prevMonth.toString(),
      },
    ];
  })();

  const delayOptions = ((): DateOption[] => {
    const currentDate = new Date();
    const options = [];
    for (let i = 0; i < 30; i += 1) {
      const selectedDate = new Date();
      selectedDate.setDate(currentDate.getDate() + i);
      options.push({
        title: `${selectedDate.getDate()}/${selectedDate.getUTCMonth() + 1}`,
        value: i,
      });
    }
    return options;
  })();

  const getOrderCode = (values: OrderCreation): string => {
    const { location, date, code } = values;
    const prefix = ((): string => {
      let base = '';
      switch (code.toString().length) {
        case 0:
          base += '0';
        // eslint-disable-next-line
        case 1:
          base += '0';
        // eslint-disable-next-line
        case 2:
          base += '0';
        // eslint-disable-next-line
        case 3:
          base += '0';
          break;

        default:
          break;
      }
      return base;
    })();
    return defaultOptions.includes(values.prefix)
      ? `${values.prefix}${date}${location}-${prefix}${code}`
      : `${values.prefix}-${prefix}${code}`;
  };

  const formatDate = (date: string): string => {
    const splittedDateTime = date.includes('à')
      ? date.split(' ')
      : date.includes(',') ? date.split(', ') : date.split(' ');
    const splittedDate = splittedDateTime[0].split('/');
    return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}T${
      splittedDateTime[date.includes('à') ? 2 : 1]
    }`;
  };

  const checkIsOnHold = (order: Order): boolean => {
    const currentDate = new Date();
    const creationDate = new Date(formatDate(order.createdAt));
    const activeDate = new Date(creationDate);
    activeDate.setHours(0, 0, 0, 0);
    activeDate.setDate(creationDate.getDate() + order.delay);
    console.log('activeDate', order.createdAt)
    return activeDate > currentDate;
  };

  const filteredValues = orders.values
    .filter(
      (e) =>
        e.orderCode.includes(filterText.toUpperCase()) ||
        e.clientCode.includes(filterText.toUpperCase())
    )
    .filter((e) =>
      user && user.type !== 'admin' && user.type !== 'assistant'
        ? !checkIsOnHold(e)
        : true
    );

  const sortedValues = filteredValues
    .sort((a, b) => {
      if (a.assigned === 0 && b.assigned !== 0) {
        return -1;
      }
      return 1;
    })
    .sort((a, b) => {
      if (checkIsOnHold(a) && !checkIsOnHold(b)) {
        return -1;
      }
      return 1;
    });

  const clientOptions = clients.values.map((option) => option.codeClient);

  const getFabricationDate = (createdDateString: string, delay: number) => {
    const createdDay = parseInt(
      createdDateString.split(' ')[0].split('/')[0],
      10
    );
    const createdMonth = parseInt(
      createdDateString.split(' ')[0].split('/')[1],
      10
    );
    const createdYear = parseInt(
      createdDateString.split(' ')[0].split('/')[2],
      10
    );
    const createdDate = new Date(createdYear, createdMonth - 1, createdDay);

    createdDate.setHours(0, 0, 0, 0);
    createdDate.setDate(createdDate.getDate() + delay);
    return `${createdDate.getDate()}/${
      createdDate.getMonth() + 1
    }/${createdDate.getFullYear()}`;
  };

  return (
    <>
      <Layout title="Commandes" action={handleOpenForm}>
        <TextField
          label="Rechercher"
          variant="outlined"
          fullWidth
          placeholder="Rechercher"
          onChange={(e): void => {
            clearInterval();
            setFilterText(e.target.value);
            debounceOrderFetch(e.target.value);
          }}
        />
        <TableContainer style={{ height: 'calc(100% - 56px)' }}>
          <Table stickyHeader>
            <TableHead style={{ position: 'sticky' }}>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  N° commande
                  <br />
                  Code client
                </TableCell>
                <TableCell>Personne assignée</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.values &&
                sortedValues.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={(): void => {
                      handleOpenDialog(row.assigned === 0);
                      setSelectedOrder(row);
                    }}
                  >
                    <TableCell>
                      <StatusIndicator
                        active={Boolean(row.assigned)}
                        isOnHold={checkIsOnHold(row)}
                      />
                    </TableCell>
                    <TableCell>
                      <>
                        {`${row.orderCode} `}
                        <br />
                        {` ${row.clientCode}`}
                        <br />
                        {row.createdAt}
                        <br />
                        {`Date de fabrication: ${getFabricationDate(
                          row.createdAt,
                          row.delay
                        )}`}
                      </>
                    </TableCell>
                    <TableCell>
                      {row.assigned !== 0 &&
                        `${row.userFName} ${row.userLName}`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Layout>
      <Formik
        initialValues={{
          prefix: 'BPC',
          location: 'CP01',
          date: dateOptions[0].value,
          code: '',
          clientCode: '',
          delay: '',
        }}
        onSubmit={submitOrder}
        validationSchema={yup.object().shape({
          code: yup.number().required().max(9999, 'Too long!'),
          clientCode: yup
            .string()
            .required()
            .oneOf(
              clients.values
                ? clients.values.map((client) => client.codeClient)
                : [],
              'This Client code does not exist.'
            ),
          delay: yup.number().required(),
        })}
      >
        {(form): JSX.Element => (
          <Dialog
            open={openForm}
            title="Ajouter Commande"
            onClose={(): void => handleCloseForm(form)}
            onValidate={(): void => {
              if (next) {
                handleSubmit(form);
              } else {
                handleNext();
              }
            }}
            isValid={next ? form.isValid : true}
          >
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              {!next ? (
                <>
                  {' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseLine',
                      justifyContent: 'space-between',
                      width: 200,
                    }}
                  >
                    <Select
                      label=""
                      options={prefixOptions}
                      fieldName="prefix"
                    />
                    {defaultOptions.includes(form.values.prefix)&& (
                      <Select
                        label="Date"
                        options={dateOptions}
                        fieldName="date"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseLine',
                      justifyContent: 'space-between',
                      width: 200,
                    }}
                  >
                    {defaultOptions.includes(form.values.prefix) && (
                      <Select
                        label=""
                        options={locationOptions}
                        fieldName="location"
                      />
                    )}
                    <TextField
                      label="Numéro"
                      autoFocus
                      onChange={(e): void => form.handleChange(e)}
                      name="code"
                      inputMode="numeric"
                      error={Boolean(form.errors.code)}
                      helperText={form.errors.code}
                      style={{ maxWidth: 75 }}
                    />
                  </div>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    onChange={(e, value): void => handleChange(e, value, form)}
                    options={clientOptions}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        label="Code client"
                        onChange={(e): void => handleUserInputChange(e)}
                        margin="normal"
                        name="codeClient"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                  <TextField
                    label="N° de commande"
                    name=""
                    value={getOrderCode(form.values)}
                    disabled
                  />
                </>
              ) : (
                <Select label="" options={delayOptions} fieldName="delay" />
              )}
            </Form>
            {error && (
              <Typography color="error">
                Le numéro de commande existe déjà
              </Typography>
            )}
          </Dialog>
        )}
      </Formik>
      {selectedOrder && (
        <>
          <Dialog
            open={openDialog}
            title="Etes-vous sûr ?"
            onClose={(): void => setOpenDialog(false)}
            onValidate={
              selectedOrder.assigned === 0 ? assignOrder : unassignOrder
            }
            isValid
          >
            {`Etes-vous sur de vouloir ${
              selectedOrder.assigned === 0 ? 'prendre' : 'desassigner'
            } la commande ${selectedOrder.orderCode}?`}
          </Dialog>
          <Dialog
            open={openAdminDialog}
            title="Que voulez-vous faire ?"
            onClose={(): void => setOpenAdminDialog(false)}
          >
            <Button
              style={{ marginBottom: 10 }}
              fullWidth
              variant="outlined"
              onClick={deleteOrder}
              color="secondary"
              disabled={serverActions.isLoading}
            >
              {`Supprimer la commande ${selectedOrder.orderCode}`}
            </Button>
            <Button
              fullWidth
              onClick={
                selectedOrder.assigned === 0 ? assignOrder : unassignOrder
              }
              variant="outlined"
              disabled={serverActions.isLoading}
            >
              {`${
                selectedOrder.assigned === 0 ? 'Prendre' : 'Desassigner'
              } la commande ${selectedOrder.orderCode}`}
            </Button>
            {checkIsOnHold(selectedOrder) && (
              <Button
                style={{ marginTop: 10 }}
                fullWidth
                variant="outlined"
                onClick={activateOrder}
                disabled={serverActions.isLoading}
              >
                {`Activer la commande ${selectedOrder.orderCode}`}
              </Button>
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default OrderPage;
