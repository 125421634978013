import React, { useContext, useEffect } from 'react';
import './App.css';

import { Switch, Route, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';

import LoginPage from './Scene/Login/LoginPage';
import RenewPasswordPage from './Scene/Login/RenewPassword';
import { useSessionId, useFetch } from './Hooks/Hooks';
import Order from './Scene/Order/OrderPage';
import Users from './Scene/Users';
import Clients from './Scene/Clients';
import { UserContext } from './Context/UserContext';
import NotFound from './Component/NotFound';

const App: React.FC = () => {
  const { sessionId } = useSessionId();
  const [isLoading, setIsLoading] = React.useState(false);
  const ctx = useContext(UserContext);
  const user = useFetch('/user');
  const location = useLocation();
  const fetch = React.useCallback(async () => {
    setIsLoading(true);
    await user
      .fetch()
      .then(() => ctx.setUser(user.values))
      .finally(() => setIsLoading(false));
  }, [user, ctx]);
  useEffect(() => {
    if (!isLoading && sessionId && !ctx.user) fetch();
  }, [ctx, fetch, location.pathname, sessionId, isLoading]);

  return (
    <>
      <Switch>
        <Redirect from="/" to="/login" exact />
        {sessionId && <Redirect from="/login" to="/orders" exact />}
        {!sessionId && location.pathname !== '/login' && (
          <Redirect to="/login" exact />
        )}
        <Route exact path="/login">
          <div className="App">
            <LoginPage />
          </div>
        </Route>
        <Route exact path="/login/password">
          <div className="App">
            <RenewPasswordPage />
          </div>
        </Route>
        <Route exact path="/orders">
          <Order />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route exact path="/clients">
          <Clients />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default App;
