import React from 'react';
import { Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { Select as MuiSelect } from 'formik-material-ui';

interface Option {
  title: string;
  value: React.ReactText;
}

interface Props {
  options: Option[];
  label: string;
  fieldName: string;
}

const Select: React.FC<Props> = (props: Props) => {
  const { options, label, fieldName } = props;

  return (
    <FormControl>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Field
        component={MuiSelect}
        name={fieldName}
        inputProps={{
          id: 'age-simple',
        }}
        style={{ minWidth: 100 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export default Select;
