import React from 'react';
import { useHistory } from 'react-router';
import { Typography, TextField, Button } from '@material-ui/core';
import { UserContext } from '../../Context/UserContext';
import { useServerModification } from '../../Hooks/Hooks';

const RenewPassword: React.FC = () => {
  const [newPassword, setNewPassword] = React.useState('');
  const user = React.useContext(UserContext);
  const serverActions = useServerModification();
  const history = useHistory();
  const updatePassword = async (): Promise<void> => {
    serverActions
      .sendPut(`/users/${user.user.id}`, {
        ...user.user,
        password: newPassword,
      })
      .then(() => history.push('/orders'));
  };
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Typography variant="h5">Changer de mot de passe</Typography>
      <Typography variant="body1">
        C&apos;est votre première connexion ou votre mot de passe a été
        reinitialisé
      </Typography>
      <Typography variant="body1">
        Par sécurité veuillez choisir un nouveau mot de passe
      </Typography>
      <TextField
        label="Nouveau mot de passe"
        type="password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setNewPassword(e.target.value)
        }
      />
      <Button onClick={updatePassword}>Confirmer</Button>
    </div>
  );
};

export default RenewPassword;
