import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/map';
import 'core-js/features/array/includes';
import 'core-js/features/array/sort';
import 'core-js/features/array/filter';
import { CookiesProvider } from 'react-cookie';
import { HashRouter } from 'react-router-dom';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './Context/UserContext';

ReactDOM.render(
  <HashRouter>
    <CookiesProvider>
      <UserContextProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </UserContextProvider>
    </CookiesProvider>
  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
