import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

interface Props {
  children: ReactNode;
}
interface User {
  active: number;
  email: string;
  firstName: string;
  id: number;
  initial: boolean;
  lastName: string;
  type: string;
}
interface UserContext {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

/* eslint-disable-next-line */
const UserContext = createContext<any>({});

const UserContextProvider: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<User>();
  const value = { user: state, setUser: setState };
  const { children } = props;
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
